<template>
  <div class="clientPlatformCart">
    <company-logo
      class="mb-3"
      :image-name="companyInformation.logo"
      :company_slug="companyInformation.code"
      :thumbnail="true"
    />
    {{ companyInformation.name }}
  </div>
</template>

<script>
import CompanyLogo from "@/components/UI/images/CompanyLogo";

export default {
  props: {
    companyInformation: {},
  },
  name: "ClientPlatformCart",
  components: { CompanyLogo },
};
</script>

<style></style>
