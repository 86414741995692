<template>
  <h1 class="mt-0 adminHomePage__title">{{ $t("platformStatistics") }}</h1>
  <div class="row">
    <div class="col-lg-6">
      <div class="adminHomePage__statistic">
        <div class="d-flex justify-content-between">
          <div>
            <div class="adminHomePage__statistic-count">
              {{ employees.currentCount }}
            </div>
            <div class="adminHomePage__statistic-text">
              {{ $t("sidebarEmployeesBtn") }}
            </div>
          </div>
          <div class="adminHomePage__statistic-text">
            <grow-statistics-icon
              :class="{ 'icon-rotate': employees.currentCount < 0 }"
              class="me-2"
            />
            {{ employees.currentCount < 0 ? "" : "+" }}
            {{ employees.currentCount }} {{ $t("fromLastMonth") }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="h-100 d-flex flex-column justify-content-between">
        <div class="adminHomePage__statistic">
          <div class="d-flex justify-content-between">
            <div>
              <div class="adminHomePage__statistic-count">
                {{ companies.currentCount }}
              </div>
              <div class="adminHomePage__statistic-text">
                {{ $t("sidebarCompanyBtn") }}
              </div>
            </div>
            <div class="adminHomePage__statistic-text">
              <grow-statistics-icon
                :class="{ 'icon-rotate': employees.currentCount < 0 }"
                class="me-2"
              />
              {{ companies.currentCount < 0 ? "" : "+" }}
              {{ companies.currentCount }} {{ $t("fromLastMonth") }}
            </div>
          </div>
        </div>
        <div class="adminHomePage__statistic">
          <div class="d-flex justify-content-between">
            <div>
              <div class="adminHomePage__statistic-count">
                {{ experts.currentCount }}
              </div>
              <div class="adminHomePage__statistic-text">
                {{ $t("expertsAdminTitle") }}
              </div>
            </div>
            <div class="adminHomePage__statistic-text">
              <grow-statistics-icon
                class="me-2"
                :class="{ 'icon-rotate': experts.currentCount < 0 }"
              />
              {{ experts.currentCount < 0 ? "" : "+" }}
              {{ experts.currentCount }} {{ $t("fromLastMonth") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h2 class="mb-0 adminHomePage__title">{{ $t("transactionsStatistics") }}</h2>
  <div class="adminHomePage__statisticSwitch">
    <div
      class="adminHomePage__statisticSwitchButton"
      :class="{
        'adminHomePage__statisticSwitchButton-active': statistics.employees,
      }"
      @click="
        statistics.employees = true;
        statistics.companies = false;
      "
    >
      {{ $t("sidebarEmployeesBtn") }}
    </div>
    <div
      class="adminHomePage__statisticSwitchButton"
      :class="{
        'adminHomePage__statisticSwitchButton-active': statistics.companies,
      }"
      @click="
        statistics.employees = false;
        statistics.companies = true;
      "
    >
      {{ $t("sidebarCompanyBtn") }}
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <transactions-statistic-cart
        :employee="true"
        :transactions-information="transactions.employees.current"
        v-if="statistics.employees"
      />
      <transactions-statistic-cart
        :employee="false"
        :transactions-information="transactions.companies.current"
        v-if="statistics.companies"
      />
    </div>
    <div class="col-lg-6">
      <transactions-statistic-cart
        :employee="true"
        :transactions-information="transactions.employees.estimate"
        :estimate="true"
        v-if="statistics.employees"
      />
      <transactions-statistic-cart
        :employee="false"
        :transactions-information="transactions.companies.estimate"
        :estimate="true"
        v-if="statistics.companies"
      />
    </div>
  </div>
</template>

<script>
import TransactionsStatisticCart from "@/components/elements/transactions/TransactionsStatiscticCart";
import GrowStatisticsIcon from "@/components/UI/icons/arrows/GrowStatisticsIcon";

export default {
  name: "AdminHomePage",
  components: { GrowStatisticsIcon, TransactionsStatisticCart },
  data() {
    return {
      statistics: {
        companies: false,
        employees: true,
      },
      companies: {
        currentCount: 0,
        lastMonthDifference: 0,
      },
      experts: {
        currentCount: 0,
        lastMonthDifference: 0,
      },
      employees: {
        currentCount: 0,
        lastMonthDifference: 0,
      },
      transactions: {
        employees: {
          current: {
            lastMonthTrancsCount: 0,
            successTrancsCount: 0,
            trancsAmount: 0,
            trancsAmountPercDifference: 0,
            trancsCount: 0,
            trancsCountPercDifference: 0,
          },
          estimate: {
            lastMonthTrancsCount: 0,
            successTrancsCount: 0,
            trancsAmount: 0,
            trancsAmountPercDifference: 0,
            trancsCount: 0,
            trancsCountPercDifference: 0,
          },
        },
        companies: {
          current: {
            lastMonthTrancsCount: 0,
            successTrancsCount: 0,
            trancsAmount: 0,
            trancsAmountPercDifference: 0,
            trancsCount: 0,
            trancsCountPercDifference: 0,
          },
          estimate: {
            lastMonthTrancsCount: 0,
            successTrancsCount: 0,
            trancsAmount: 0,
            trancsAmountPercDifference: 0,
            trancsCount: 0,
            trancsCountPercDifference: 0,
          },
        },
      },
    };
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    getStatistics() {
      this.$http.get("/statistics").then(({ data }) => {
        this.companies = data.body.companies;
        this.employees = data.body.employees;
        this.experts = data.body.experts;
        this.transactions = data.body.transactions;
      });
    },
  },
};
</script>

<style></style>
