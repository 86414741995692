<template>
  <main-layout>
    <employee-home-page v-if="loginUser.role_id === 4" />
    <company-home-page v-if="loginUser.role_id === 3" />
    <expert-home-page v-if="loginUser.role_id === 2" />
    <admin-home-page v-if="loginUser.role_id === 1" />
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeHomePage from "@/components/pages-templates/home/EmployeeHomePage";
import ExpertHomePage from "@/components/pages-templates/home/ExpertHomePage";
import AdminHomePage from "@/components/pages-templates/home/AdminHomePage";
import MainLayout from "@/layouts/MainLayout";
import CompanyHomePage from "@/components/pages-templates/home/CompanyHomePage";

export default {
  name: "HomePage",
  components: {
    CompanyHomePage,
    MainLayout,
    AdminHomePage,
    ExpertHomePage,
    EmployeeHomePage,
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
