<template>
  <bar-chart :options="chartOptions" :chartData="chartData" />
</template>

<script>
import { BarChart } from "vue-chart-3";
import { format } from "date-fns";

export default {
  props: {
    appointmentsCountData: {
      type: Array,
      default: [],
    },
  },
  name: "StatisticsChartCountAppointment",
  components: {
    BarChart,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t("sidebarAppointmentsBtn"),
            data: [],
            backgroundColor: [
              "rgba(119,206,255, 1)",
              "rgba(0,121,175, 1)",
              "rgba(18,62,107, 1)",
            ],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: this.$t("TotalMonthlyAppointment"),
          },
        },
      },
    };
  },
  mounted() {
    this.setChartValues(this.appointmentsCountData);
    setTimeout(() => {
      this.chartOptions.plugins.title.text = this.$t("TotalMonthlyAppointment");
      this.chartData.datasets[0].label = this.$t("sidebarAppointmentsBtn");
    }, 100);
  },
  methods: {
    monthLabel(value) {
      return format(
        new Date(new Date().getFullYear(), value - 1, 15),
        "MMMMMMM"
      );
    },
    setChartValues(value) {
      for (let valueKey in value) {
        this.chartData.labels.unshift(this.monthLabel(value[valueKey].month));
        this.chartData.datasets[0].data.unshift(value[valueKey].value);
      }
      this.chartData.datasets[0].data.push(
        Math.max(...this.chartData.datasets[0].data) + 5
      );
    },
  },
  watch: {
    appointmentsCountData: {
      deep: true,
      handler(value) {
        this.setChartValues(value);
      },
    },
  },
};
</script>

<style></style>
