<template>
  <h2 class="companyHomePage__title">{{ $t("participationCompany") }}</h2>
  <div class="row mb-50">
    <div class="col-12 col-lg-6">
      <statistics-chart-count-appointment
        :appointments-count-data="appointmentsCount"
      />
    </div>
    <div class="col-12 col-lg-6">
      <statistics-chart-appointment-mode
        :count-by-services-data="countByServices"
      />
    </div>
  </div>
  <h2 v-if="transactions.length > 0" class="companyHomePage__title">
    {{ $t("activityFeed") }}
  </h2>
  <div v-if="transactions.length > 0" class="row companyHomePage__tableTitle">
    <div class="col-3">
      {{ $t("transactionDate") }}
    </div>
    <div class="col-3">
      {{ $t("coverageSubscription") }}
    </div>
    <div class="offset-1 col-2">
      {{ $t("totalPaid") }}
    </div>
    <div class="offset-1 col-2">
      {{ $t("Status") }}
    </div>
  </div>
  <div v-for="transaction in transactions" :key="transaction.id">
    <company-transaction-cart :transaction="transaction" />
  </div>
  <!--  <div v-if="transactions.length > 0" class="employee__appointments-history mt-30">-->
  <!--    <base-button link="/history">{{ $t('allAppointmentHistory') }}</base-button>-->
  <!--  </div>-->
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import StatisticsChartCountAppointment from "@/components/elements/statistics/StatisticsChartCountAppointment.vue";
import StatisticsChartAppointmentMode from "@/components/elements/statistics/StatisticsChartAppointmentMode.vue";
import CompanyTransactionCart from "@/components/elements/transactions/CompanyTransactionCart.vue";

export default {
  name: "CompanyHomePage",
  components: {
    CompanyTransactionCart,
    StatisticsChartAppointmentMode,
    StatisticsChartCountAppointment,
    BaseButton,
  },
  data() {
    return {
      transactions: [],
      appointmentsCount: [],
      countByServices: [],
    };
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    getStatistics() {
      this.$http.get("/statistics/company").then(({ data }) => {
        this.appointmentsCount = data.body.appointmentsCount;
        this.countByServices = data.body.countByServices;
      });
      this.$http.get("/company-transactions/company/past").then(({ data }) => {
        this.transactions = data.body;
      });
    },
  },
  computed: {},
};
</script>

<style></style>
