<template>
  <div class="row">
    <h1>{{ $t("employeeHomePageTitle") }}</h1>
  </div>
  <div>
    {{ $t("employeeHomePageYouHave")
    }}<span class="purple">{{
      appointmentCarts.length > 0 ? appointmentCarts.length : $t("no")
    }}</span>
    {{ $t("employeeHomePageUpcoming") }}
  </div>
  <div v-if="haveAppointments" class="row employee__appointments">
    <div
      v-for="(appointment, index) in appointmentCarts"
      class="col-12 col-lg-6"
    >
      <appointments
        v-if="index < 4"
        :key="appointment.id"
        :upcomingAppointments="index === 0"
        :nearest="index === 0"
        :appointment="appointment"
      />
    </div>
  </div>
  <div v-if="!haveAppointments" class="row employee__appointments">
    <div class="col-12 col-lg-6">
      <div class="templateHaveContent">
        <div>
          {{ $t("employeeHomePageTemplateText") }}
          <span class="weight-500">{{ $t("headerCalendarBtn") }}</span>
          {{ $t("employeeHomePageTemplateText2") }}
        </div>
        <base-button link="/calendar" :calendar="true">
          <template #icon>
            <calendar-icon />
          </template>
          {{ $t("headerCalendarBtn") }}
        </base-button>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="templateNoContent"></div>
    </div>
  </div>
  <div class="employee__recent-appointments">
    <h2>{{ $t("employeeHomePageRecent") }}</h2>
    <div v-if="haveRecentAppointments" class="row appointmentHistoryPage">
      <div
        v-for="(appointment, index) in recentAppointments"
        class="col-12 col-md-6 col-xxl-3"
      >
        <recent-appointments
          v-if="index < 4"
          :recent-appointment="appointment"
        />
      </div>
    </div>
    <div v-if="!haveRecentAppointments" class="row appointmentHistoryPage">
      <div class="col-12 col-md-6 col-xxl-3 mb-30">
        <div class="templateRecent"></div>
      </div>
      <div class="col-12 col-md-6 col-xxl-3 mb-30">
        <div class="templateRecent"></div>
      </div>
      <div class="col-12 col-md-6 col-xxl-3 mb-30">
        <div class="templateRecent"></div>
      </div>
      <div class="col-12 col-md-6 col-xxl-3 mb-30">
        <div class="templateRecent"></div>
      </div>
    </div>
    <div
      v-if="haveRecentAppointments && recentAppointments.length > 4"
      class="employee__appointments-history"
    >
      <base-button link="/history"
        >{{ $t("allAppointmentHistory") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import RecentAppointments from "@/components/elements/appointment/RecentAppointments";
import Appointments from "@/components/elements/appointment/Appointments";
import { mapGetters } from "vuex";
import CalendarIcon from "@/components/UI/icons/CalendarIcon";

export default {
  name: "EmployeeHomePage",
  components: {
    CalendarIcon,
    Appointments,
    RecentAppointments,
    BaseButton,
  },
  data() {
    return {
      recentAppointments: [],
      activeSort: [true, false, false],
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    sortBy(data) {
      this.activeSort.forEach((element, e) => {
        this.activeSort[e] = e === data;
      });
    },
    getHistory() {
      this.$http.get("/appointments/recent").then(({ data }) => {
        this.recentAppointments = data.body;
      });
    },
  },
  computed: {
    ...mapGetters({
      appointmentCarts: "APPOINTMENTS",
    }),
    haveAppointments() {
      return this.appointmentCarts.length > 0;
    },
    haveRecentAppointments() {
      return this.recentAppointments.length > 0;
    },
  },
};
</script>

<style></style>
