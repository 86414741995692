<template>
  <pie-chart :options="chartOptions" :chartData="chartData" />
</template>

<script>
import Chart from 'chart.js/auto';
import { PieChart } from "vue-chart-3";

export default {
  props: {
    countByServicesData: {
      type: Array,
      default: [],
    },
  },
  name: "StatisticsChartAppointmentMode",
  components: {
    PieChart,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: this.$t("AppointmentByType"),
          },
        },
      },
    };
  },
  mounted() {
    this.setChartValues(this.countByServicesData);
    setTimeout(() => {
      this.chartOptions.plugins.title.text = this.$t("AppointmentByType");
    }, 100);
  },
  methods: {
    setChartValues(value) {
      let backgroundColor = [
        "rgba(119,206,255, 1)",
        "rgba(0,121,175, 1)",
        "rgba(18,62,107, 1)",
      ];
      value.forEach((item, i) => {
        this.chartData.labels.push(item.serviceName);
        this.chartData.datasets[0].data.push(item.value);
        if (i >= 3) {
          let currentColor = backgroundColor[i - 3]
            .replace(/[^,\d]/g, "")
            .split(",");
          backgroundColor[i] = `rgba(${currentColor[0]},${currentColor[1]},${
            currentColor[2]
          },${currentColor[3] - 0.25 > 0 ? currentColor[3] - 0.25 : 1})`;
        }
      });
      this.chartData.datasets[0].backgroundColor = backgroundColor;
    },
  },
  watch: {
    countByServicesData: {
      deep: true,
      handler(value) {
        this.setChartValues(value);
      },
    },
  },
};
</script>

<style scoped></style>
