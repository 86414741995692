<template>
  <div
    class="transactionsStatisticCart"
    :class="{ transactionsStatisticCart__gradient: !estimate }"
  >
    <div>
      <div class="transactionsStatisticCart__title">
        {{ employee ? $t("sidebarEmployeesBtn") : $t("sidebarCompanyBtn") }}
        {{ $t("sidebarTransactionsBtn") }}
      </div>
      <div class="transactionsStatisticCart__subtitle">
        <div v-if="estimate">
          {{ $t("estimateFor") }}
          <span class="weight-500">
            <data-formatted date-format="MMM" :data="new Date()" />
          </span>
          <span class="weight-400">
            <data-formatted date-format=", yyyy:" :data="new Date()" />
          </span>
        </div>
        <div v-else class="d-flex">
          <span class="weight-500">
            01<data-formatted date-format="-dd MMM" :data="new Date()"
          /></span>
          <span class="weight-400"
            ><data-formatted date-format=", yyyy" :data="new Date()"
          /></span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <div
          class="transactionsStatisticCart__number transactionsStatisticCart__number-mb-6"
        >
          {{ transactionsInformation.trancsCount }}
          <small>{{ $t("transactions") }}</small>
        </div>
        <div class="transactionsStatisticCart__statistics">
          <grow-statistics-icon
            :white="!estimate"
            class="me-2"
            :class="{
              'icon-rotate':
                transactionsInformation.trancsCountPercDifference < 0,
            }"
          />
          {{ transactionsInformation.trancsCountPercDifference < 0 ? "" : "+" }}
          {{ transactionsInformation.trancsCountPercDifference }}%
          {{ $t("fromLastMonth") }}
        </div>
      </div>
      <div>
        <div
          class="transactionsStatisticCart__number transactionsStatisticCart__number-mb-6"
        >
          €{{ transactionsInformation.trancsAmount }}
        </div>
        <div class="transactionsStatisticCart__statistics">
          <grow-statistics-icon
            :white="!estimate"
            class="me-2"
            :class="{
              'icon-rotate':
                transactionsInformation.trancsAmountPercDifference < 0,
            }"
          />
          {{
            transactionsInformation.trancsAmountPercDifference < 0 ? "" : "+"
          }}
          {{ transactionsInformation.trancsAmountPercDifference }}%
          {{ $t("fromLastMonth") }}
        </div>
      </div>
    </div>
    <div>
      <div class="transactionsStatisticCart__progress">
        {{ transactionsInformation.successTrancsCount }}
        <small
          >/ {{ transactionsInformation.trancsCount }}
          {{ $t("successful") }}</small
        >
      </div>
      <div class="progress-bar blue stripes">
        <span class="transition" :style="'width:' + progress"></span>
      </div>
    </div>
  </div>
</template>

<script>
import DataDay from "@/components/components-helpers/DataDay";
import GrowStatisticsIcon from "@/components/UI/icons/arrows/GrowStatisticsIcon";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  props: {
    employee: Boolean,
    estimate: false,
    transactionsInformation: {},
  },
  name: "TransactionsStatisticCart",
  components: { DataFormatted, GrowStatisticsIcon, DataDay },
  computed: {
    progress() {
      let denominator =
        this.transactionsInformation.trancsCount === 0
          ? 1
          : this.transactionsInformation.trancsCount;
      let dividend = this.transactionsInformation.successTrancsCount;
      let interest = (dividend / denominator) * 100;
      return interest + "%";
    },
  },
};
</script>

<style></style>
